@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap');

/* VARIABLES */
:root {
  /* 
  FONTS 
  */
  /* styles */
  --heading-font: 'Roboto Mono', monospace;
  --body-font: 'Roboto', Arial, Verdana, sans-serif;
  --liga-setting: 'liga' 1;
  /* sizes */
  --base-font-size: 0.875rem;
  --base-line-height: 1.5rem;

  /* colors */
  /* --off-black: #1f1f1f; */
  --off-black: #0f0f0f;
  --off-white: #eef2f1;
  --pure-white: #fff;
  --turqoise: #2cdec0;
  --x-turqoise: #00bfa5;
  /* --orange: #fb6520; */
  --orange: #0053d6;
  --accent-color: #0053d6;
  /* --orange: #ffb300; */

  /* animation timing functions */
  --slide-up: cubic-bezier(0.26, 1, 0.48, 1);
  --border-fade-in: cubic-bezier(0.32, 0.94, 0.6, 1);
}
